@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/** HomeUser container  **/
.HomeUser_container{
    display: flex;
  /*   width: 95%; */
    padding-left: 5vw;
    padding-right: 5vw;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  /*  gap: 88px; */  /* the default gap*/
     gap: 30px;
    /* style text */
    color: #3F52FC;
   /* leading-trim: both;*/
   /* text-edge: cap;*/
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
   /* cursor: pointer;*/
   overflow-y: hidden; /* Prevent vertical scrolling */
}
 /* Start To Design Mobile */
@media (max-width: 600px) {
    .HomeUser_container {
          display: flex;
        /*   width: 95%; */
          padding-left: 10px;
          padding-right: 10px;
          flex-direction: column;
          align-items: flex-start;
        /*gap: 88px; *//* the default gap*/
          gap: 20px;
          /* style text */
          color: #3F52FC;
         /* leading-trim: both;*/
         /* text-edge: cap;*/
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 37.5px */
          letter-spacing: -0.475px;
          text-transform: capitalize;
         /* cursor: pointer;*/
    }
}
.HomeUser_Sec1 {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: -1;
    padding-left: 20px;
}

.HomeUser_Sec1_Panner {
    position: relative;
    width: 70vw;
    padding-left: 2vh;
}
.HomeUser_Sec1_title{
    position: absolute;
    max-width:  25vw;
    left: 150px;
    top: 50px;
}
.HomeUser_Sec1_title_h1{
    font-size: 25px;
    color:#EDEFFF;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
}
.HomeUser_Sec1_title_p {
    font-size: 18px;
    color:#EDEFFF;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
}
@media (max-width: 600px) {
    .HomeUser_Sec1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        z-index: -1;
    }
    .HomeUser_Sec1_title{
        position: absolute;
        max-width:  150px;
        left: 40px;
        top: 0;
    }
    .HomeUser_Sec1_title_h1{
        font-size: 16px;
        color:#EDEFFF;
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
    }
    .HomeUser_Sec1_title_p {
        font-size: 8px;
        color:#EDEFFF;
        font-family: Inter;
        font-style: normal;
        font-weight: 100;
    }
}
/* sec2 HomeUser category Hash */
.HomeUser_Sec2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    padding: 24px;
  
}
.HomeUser_Sec2_part1{
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
/**/
.HomeUser_Sec2_part2{
    display: flex;
    padding: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 15px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 16px;
    background: #EDEFFF;
    position: relative;
    max-width: 70vw;
    z-index: -1;
}
/* Items */
.HomeUser_Sec2_part2_items{
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.HomeUser_Sec2_part2_icon{
    display: none;
    position: absolute;
    right: 10px;
    bottom: -5.858px;
} 
 /* start design section 2 */
 @media (max-width: 600px) {
    .HomeUser_Sec2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        padding: 10px;
    }
    .HomeUser_Sec2_part1{
        color: #3F52FC;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 37.5px */
        letter-spacing: -0.475px;
        text-transform: capitalize;
    }
    .HomeUser_Sec2_part2{
        display: flex;
        padding: 10px;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        border-radius: 16px;
        background: #EDEFFF;
        position: relative;
        z-index: -1;
    }
    .HomeUser_Sec2_part2_items{
        display: flex;
        padding: 5px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #FFF;
        /* lndex drop shadow */
        box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
    }
    .HomeUser_Sec2_part2_icon{
        position: absolute;
        left: 150px;
        bottom: -5.858px;
        width: 20px;
        display: none;
    } 
}
.HomeUser_Sec3{
    display: flex;
    justify-content: space-between; 
    padding: 8px 0px;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    align-self: stretch;
    width: 70vw;
    
}

.HomeUser_Sec3_Part1{
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.HomeUser_Sec3_Part2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}
@media (max-width: 600px) {
    .HomeUser_Sec3{
        display: flex;
        padding: 8px 0px;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        
    }
    .HomeUser_Sec3_Part1{
        color: #3F52FC;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 37.5px */
        letter-spacing: -0.475px;
        text-transform: capitalize;
    }
    .HomeUser_Sec3_Part2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }
    .HomeUser_Sec3_Part2 img {
        width: 25px;
    }
}
.HomeUser_Sec4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
    scroll-behavior: smooth; /* Smooth scrolling effect */
    padding: 10px; /* Add padding for spacing */
}

.HomeUser_Sec4::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
}

/* End the section about Course Container  */
/* Sec5 Instrector Container  */
.HomeUser_Sec5 {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 10px;
    align-self: stretch;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Optional for smooth scrolling */
    transition: transform 0.5s ease; /* Apply transition to transform property */
    padding-left: 24px;
    padding-right: 70px;
    width: 100vw; /* Arbitrary large width */
}
.HomeUser_Sec5::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
}
.HomeUser_Sec5 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* sec 6 about Article container */
.HomeUser_Sec6 {
    display: flex;

    scroll-behavior: smooth;
    align-items: flex-start;
    gap: 32px;
    padding-left: 24px;

    width: 100%; /* Ensure the container takes full width */
    overflow-x: auto; /* Enable horizontal scrolling */
    flex-wrap: nowrap; /* يمنع العناصر من النزول للأسفل */
}

.HomeUser_Sec6 > * {
    min-width: 300px; /* عرض أدنى لكل عنصر داخل القسم */

    flex-shrink: 0;  /* يمنع تصغير العناصر */
}
.HomeUser_Sec6::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
}
.HomeUser_Sec6 {
   /* -ms-overflow-style: none;  /* IE and Edge */
  
}
/* Start Style Section 7  about Student Review */
.HomeUser_Sec7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    width: 70vw;
 
}
.HomeUser_Sec7_Part1{
display: flex;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 20px;
/* start style header text */
color: #3F52FC;
font-family: Inter;
font-size: 35.06px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 58.59px */
letter-spacing: -0.742px;
text-transform: capitalize;
}
.HomeUser_Sec7_Part2_Container {
    display: flex;
    width: 60vw;
    padding: 32px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 8px;
    background: #EDEFFF; 
    position: relative;
    z-index: -1;
}
.HomeUser_Sec7_Part2_Container_Text {
    color: #3F52FC;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.HomeUser_Sec7_Part2_Container_Star{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}
.HomeUser_Sec7_Part2_Container_Student{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.HomeUser_Sec7_Part2_Container_Student_Name {
    color: #18172F;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}   
.HomeUser_Sec7_Part2_Container_Student_Role{
    color: #7E7E8C;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
@media (max-width: 600px) {
    .HomeUser_Sec7 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
     
    }
    .HomeUser_Sec7_Part1{
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* start style header text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 58.59px */
    letter-spacing: -0.742px;
    text-transform: capitalize;
    }
    .HomeUser_Sec7_Part2_Container {
        display: flex;
        width: 80%;
        padding: 32px 24px 24px 24px;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        border-radius: 8px;
        background: #EDEFFF; 
        position: relative;
    }
    .QouteIcon {
        width: 204px;
        height: 172.589px;
        position: absolute;
        right: 19px;
        bottom: -30.294px;
        display: none;
        }
}
.QouteIcon {
width: 204px;
height: 172.589px;
position: absolute;
right: 19px;
bottom: -30.294px;
}
/* Start To design Section 8 (Footer) */
.HomeUser_Sec8_Footer{
    display: flex;
    padding: 40px 80px 56px 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background: #F8F9FF;
    margin-top: 50px;

}
.HomeUser_Sec8_Footer_Sec1{
    display: flex;
    align-items: center;
    gap: 8px;
    /* Style Text */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    cursor: pointer;
}
/* footer Icon */
.HomeUser_Sec8_Footer_Sec2{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
/* Footer List */
.HomeUser_Sec8_Footer_Sec3{
    display: flex;
align-items: center;
gap: 40px;
}
.HomeUser_Sec8_Footer_Sec3 ul {
    display: flex;
    align-items: center;
    gap: 40px;
    /**/
    list-style-type: none;
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.HomeUser_Sec8_Footer_Sec3 ul li:hover {
    color: #3F52FC;
}
/* Social Media IN Footer */
.HomeUser_Sec8_Footer_Sec4{
    display: flex;
    width: 224px;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
/* @copy Right Section */
.HomeUser_Sec8_Footer_Sec5{
    color: #7280FE;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.023%; /* 22.404px */
}
 /* Start Style Footer To Make It Responsive  */ 
 @media (max-width: 600px) {
    .HomeUser_Sec8_Footer{
        display: flex;
        padding: 40px 80px 56px 80px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        background: #F8F9FF;
        margin-top: 25px;
    }
    /* footer Icon */
   .HomeUser_Sec8_Footer_Sec2{
       display: flex;
       flex-direction: column;
       align-items: center;
       /* gap: 2px; */
}
/* Footer List */
.HomeUser_Sec8_Footer_Sec3{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.HomeUser_Sec8_Footer_line {
    width: 350px;
}
.HomeUser_Sec8_Footer_Sec3 ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-wrap: wrap;
    /**/
    list-style-type: none;
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.HomeUser_Sec8_Footer_Sec3 ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    /**/
    list-style-type: none;
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 24px */
    letter-spacing: -0.304px;
}
 }
.link {
    text-decoration: none;
    color: inherit;
  }

