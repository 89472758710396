/* Loading Animation */
.Rview_getAll{
   display: flex;
   flex-direction: column;
}
.ReviewAndComment {
  display: flex;
  flex-direction: column;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #3F52FC;
    border-top: 4px solid transparent;
    border-radius: 50%;
  }

  
  .Review_Design { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 580px;
  }

  /* User Info */
  .Review_user_info {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;
  }
  
  .user-image-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .user-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .Review_user_Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .user-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .user-role {
    font-size: 14px;
    color: #666;
  }
  
  /* Star Rating */
  .star-rating {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .star-rating img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  /* Comment Field */
  .comment-field {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 500px !important;
  }
  
  .comment-field textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: 14px;
    resize: vertical;
  }
  
  /* Rating Buttons */
  .rating-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  
  .rating-buttons button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #3F52FC;
    color: white;
    transition: background-color 0.2s ease;
  }
  
  .rating-buttons button:last-child {
    background-color: #6c757d;
  }
  
  .rating-buttons button:hover {
    background-color: #0056b3;
  }
  
  .rating-buttons button:last-child:hover {
    background-color: #5a6268;
  }
    /* NO Review Course */
  .no-reviews-message {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin-top: 20px;
    margin-bottom: 25px;
  }