@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.Course_Saved_in_Component {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 8px;
      margin-bottom: 5px ;
      gap: 12px;
      position: absolute;
      bottom: 5px;
      right: 16px;
      cursor: pointer;
}
.Course_save_Container {
    display: flex;
    width: 316px;
    height: 410px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30) !important;
    position: relative;
 }
 .course_save_promo_container {
   display: flex;
   gap: 8px;
   padding: 0px 16px;
   justify-content: space-around;
 }
 .course_save_promo{
    display: flex;
    gap: 8px;
    width: 120px;
    height: 24px;
    /* style text */
    color: #18172F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.266px;
 }

.Course_Saved_in_Component_icons {
    width: 30px;
}
.course-cards-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    padding: 25px;
}
.Course_save_Sec1 {
    width: 316px !important; 
    border-radius: 8px 8px 0px 0px;
}

.success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 61px;
    padding: 15px;
    position: fixed; /* Change to fixed so it stays visible while scrolling */
    top: 0%;
    z-index: 1000;
    background: linear-gradient(135deg, #3b49c7, #3F52FC);
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #04FC58; /* Dark green for contrast */
    font-family: 'Poppins', sans-serif; /* Stylish font */
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    animation: slideIn 0.5s ease-in-out, fadeOut 3s 2s forwards;
}
/* Add an icon inside the message */
.success-message::before {
    content: '';
    font-size: 20px;
    margin-right: 10px;
    animation: pop 0.5s ease-in-out;
   }
.Enterprise_Title {
    color: #18172F;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.285px;
    text-transform: capitalize;
   }




/* Slide-in animation for appearance */
@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Pop effect for the icon */
@keyframes pop {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Fade-out animation for disappearing */
@keyframes fadeOut {
    to {
        opacity: 0;
    }
}