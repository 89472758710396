@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.Course_Container{
    display: flex;
    max-width: 310px;
    height: 480px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 5px 15px 10px #EDEFFF;
    border-radius: 16px 16px 16px 16px;
    cursor: pointer  ;
}
.Course_Sec1 {
    border-radius: 10px 10px 0px 0px;
}
.Course_Sec2 {
    display: flex;
    padding: 2px 8px;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    width: 100%;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.228px; 
}
.Course_Sec3_at_dashboard{
padding: 10px;
    /* style Text */
color: #3F52FC;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 30px */
letter-spacing: -0.38px;
text-transform: capitalize;
display: flex;
justify-content: flex-start;
width: 100%;
}
.Course_Sec4{
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    /* Style Text*/
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Course_Sec5{
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    /*style text */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
   }
   .Course_Sec6{
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    padding-top: 4px;
    gap: 8px;
    align-self: stretch;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    letter-spacing: -0.38px;
   }
   .Course_dashbord_Sec7{
    display: flex;
    padding: 8px 16px 16px 16px;
    justify-content: space-between ;
    width: 100%;
    align-items: center;
    align-self: stretch;
    position: absolute;
    bottom: 10px ;
   }
.Course_Dashboard_Archive {
    display: flex;
    width: 152px;
    height: 40px;
    padding: 8px 48px 8px 47px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /* Design Text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    letter-spacing: -0.304px;
}
    .Unarchived{
        display: flex;
        width: 348px;
      /*height: 496px;*/
        flex-direction: column;
        align-items: center;
        gap: 8px;
        box-shadow: 0px 10px 10px 5px #EDEFFF;
        border-radius: 16px 16px 16px 16px;
        cursor: pointer  ;
       /* filter: brightness(50%);*/ 
        filter: grayscale(70%);
    }
   .course_icon_modify {
     display: flex;
     justify-content: center;
     align-items: flex-end;
     gap: 5px;
   }
   .course_icon_modify img {
      width: 30px;
   }
  /* View List of Student */
  .students-list-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .student-header {
    display: flex;
    width: 50vw;
    height: 47px;
    padding: 0px 23px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 31px;
    flex-shrink: 0;
    flex-wrap: wrap;
    border-radius: 3px;
    background: #FFF;
   }
   .student_line{
    background: #939EFF;
    width: 100%;
    height: 1px;
   }
  .student-image {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    flex-shrink: 0;
    fill: #939EFF;
    stroke-width: 2px;
    stroke: #939EFF;
     }

   .arrow-icon {
     transition: transform 0.3s ease;
    }
      
   .rotate {
     transform: rotate(180deg);
    }
    .student-header-left {
        display: flex;
        width: 25%;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        /* Style Text */
        color: #18172F;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.38px;
        text-transform: capitalize;
    }