.branch_Card_info {
    display: flex;
    width: 297.81px;
    flex-direction: column;
    align-items: flex-start;
}
.Branch_Card_name {
    color: #3F52FC;
    font-family: Inter;
    font-size: 18.849px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 28.273px */
    letter-spacing: -0.358px;
    text-transform: capitalize;
}
.Branch_Card_bio {
    color: #3F52FC;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18.095px */
    letter-spacing: -0.229px;
    text-transform: capitalize;
}
.Branch_Card_bio span{
    color: #3F52FC;
    font-family: Inter;
    font-size: 16.063px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18.095px */
    letter-spacing: -0.229px;
    text-transform: capitalize;
}
.Branch_Card_phone {
     display: flex;
     align-items: center;
     gap: 4px;
    /* style phone text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 14.063px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18.095px */
    letter-spacing: -0.229px;
}
.Branch_Card_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    align-self: stretch;
}
.branch_delete_button {
    display: flex;
    width: 92.736px;
    height: 40px;
    padding: 8.293px 61.07px;
    justify-content: center;
    align-items: center;
    gap: 7.54px;
    border-radius: 4.524px;
    background: #FF5353;
    border: none;
    /* test design */
    color: #FFF;
    font-family: Inter;
    font-size: 15.079px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 22.619px */
    letter-spacing: -0.287px;
    text-transform: capitalize;
}