.Article_container_Dashboard {
    width: 350px;
    height: 450px;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
    border-radius: 16px 16px 16px 16px;
    gap: 8px;
    position: relative;
}
.Unarchived_Article{
    display: flex;
    width: 350px;
   /* height: 496px;*/
    flex-direction: column;
    align-items: center;
    /*gap: 8px;*/
    box-shadow: 0px 10px 10px 5px #EDEFFF;
    border-radius: 16px 16px 16px 16px;
    cursor: pointer  ;
   /* filter: brightness(50%);*/ 
    filter: grayscale(70%);
}
.article_Sec7 {
     display: flex;
     width: 350px;
     justify-content: space-between;
     align-items: center;
     padding-left: 10px;
     padding-right: 10px;
     position: absolute;
     bottom: 25px; 
}
.Article_dash_Sec3 {
    display: flex;
        padding: 4px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        color: #3F52FC;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.38px;
        text-transform: capitalize;
}
.Article_Sec1_img {
    height: 180px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
}