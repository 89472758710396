@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorNav_Container {
    display: flex;
    width: 100%;
    padding: 40px 40px 24px 40px;
    justify-content: space-between;
    align-items: flex-start;
}
.InstructorNav_Logo{
    cursor: pointer;
}
.InstructorNav_RightSide{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
}
.InstructorNav_LanguageButton{
    display: flex;
    width: 121px;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #EDEFFF;
    border: none;
    /* Text Style */
    color: #18172F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
/* Style Logout Button */
.InstructorNav_LogoutButton {
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 6px;
    background: #3F52FC;
    /* style text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
}

   
/* Notification Wrapper */
.notification-wrapper {
    position: relative;
  }
  
  /* Notification Dropdown */
  .notification-dropdown {
    position: absolute;
    top: 40px; /* Adjust based on your layout */
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px; /* Adjust width as needed */
    max-height: 300px; /* Add scroll if too many notifications */
    overflow-y: auto;
    z-index: 1000;
  }
  
  /* Notification Item */
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .notification-item.unread {
    background-color: #f9f9f9;
    font-weight: bold;
  }
  
  .notification-item.read {
    background-color: #fff;
    color: #666;
  }
  
  .notification-item:hover {
    background-color: #f1f1f1;
  }
  
  /* No Notifications Message */
  .notification-item:last-child {
    border-bottom: none;
  }