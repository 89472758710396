.search_course_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 25px;
    flex-direction: column;
  
}
.Instructor_profile {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.search_course_card {
    display: flex;
    width: 50vw;
    gap: 32px;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
   }
    /* Course Image */
   .search_course_card_img {
    background: lightgray 50% / cover no-repeat, #EDEFFF;
    width: 200px;
    height: 186px;
    border-radius: 0px 0px 0px 0px;
   }

   .search_course_card_Content {
     display: flex;
     padding: 8px 16px;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     gap: 8px;
     align-self: stretch;
    /* Text Style */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
   }

.Search_card_cart{
  display: flex;   
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.instructor_Search_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #3F52FC;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.285px;
  }
.instructor_Search_info_CreatedAT {
    color: #7E7E8C;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.228px;
}

/* filter Section */
/* Search Section */
.search-section {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .search-section h1 {
    color: #3f52fc;
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .search-section p {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  /* Search Box */
  .search-box {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .search-box input {
    width: 350px;
    padding: 12px 40px;
    font-size: 16px;
    border: 2px solid #3f52fc;
    border-radius: 25px;
    outline: none;
    transition: all 0.3s ease-in-out;
  }
  
  .search-box input:focus {
    border-color: #2c3ae7;
    box-shadow: 0 0 10px rgba(63, 82, 252, 0.3);
  }
  

  