@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
:root {
    --text-color: #000;
    --text-font-family: Inter;
    --text-font-size: 20px;
    --text-font-style: normal;
    --text-font-weight: 500;
    --text-line-height: 150%; /* 30px */
    --text-letter-spacing: -0.38px;
  }
  .EnterpriseAddInstructor_Add_Form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

.EnterpriseAddInstructor_Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
  }
  .EnterpriseAddInstructor_header {
    display: flex;
    width: 316px;
    height: 57px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: rgba(63, 82, 252, 0.50);
    /*text style*/
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
  }
  .EnterpriseAddInstructor_UploadImage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    /* Style Text */
    color: var(--text-color);
    font-family: var(--text-font-family);
    font-size: var(--text-font-size);
    font-style: var(--text-font-style);
    font-weight: var(--text-font-weight);
    line-height: var(--text-line-height);
    letter-spacing: var(--text-letter-spacing);
  }
  .Upload_icon_section {
    display: flex;
    width: 153.34px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    color: #3F52FC;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .uploaded_aadimage {
    height: 207px;
    width: 207px;
    border: 2px solid #ddd;
    border-radius: 8px;
  }
  .EnterpriseAddInstructor_Section {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     gap: 24px;
     width: 100%;

    }
    .EnterpriseAddInstructor_Section input {
        width: 550px;
        border-radius: 10px;
        background: #EDEFFF;
        padding: 7px 16px;
        border: none;
    }
    .EnterpriseAddInstructor_Section  div{
        color: var(--text-color);
        font-family: var(--text-font-family);
        font-size: var(--text-font-size);
        font-style: var(--text-font-style);
        font-weight: var(--text-font-weight);
        line-height: var(--text-line-height);
        letter-spacing: var(--text-letter-spacing);
    }
    .EnterpriseAddInstructor_Section textarea {
        width: 550px;
        border-radius: 10px;
        background: #EDEFFF;
        padding: 7px 16px;
        border: none;
    }

    /* Instructor Component  */
    .EnterpriseInstructor_Container {
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       width: 100%;
    }
    .EnterpriseInstructor_LinkAlbum{
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .EnterpriseInstructor_LinkAlbum_images{
      display: flex;
      width: 198px;
      height: 40px;
      padding: 8px 24px 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      background: #EDEFFF;
      cursor: pointer;
      /* Style Text*/
      color: #3F52FC;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      letter-spacing: -0.304px;
      text-transform: capitalize;
    }
    .EnterpriseInstructor_Card_Container {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 20px;
      padding-top: 50px;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .EnterpriseInstructor_Card {
      display: flex;
      height: 369.436px;
      width: 380px;
      padding: 18.095px;
      justify-content: center;
      align-items: center;
      gap: 15.079px;
      border-radius: 6.032px;
      background: #EDEFFF;
      position: relative; /* Required for the pseudo-element */
      overflow: hidden; /* Prevents the pseudo-element from overflowing */
      transition: transform 0.3s ease; /* Smooth hover scaling */
    }
    .EnterpriseInstructor_Card:hover {
      transform: scale(1); /* Slight zoom effect */
    }

    .EnterpriseInstructor_Card::before {
      content: "";
      position: absolute;
      left: 0%;
      bottom: 50%;
      transform: translateX(0%) rotate(0deg); /* تدوير إذا لزم الأمر */
      width: 100%; /* Half-circle width */
      height: 92%; /* Half-circle height */
      background-color: #3F52FC; /* Blue color */
      border-radius: 50%; /* Creates a perfect half-circle */
      transition: top 0.7s ease, opacity 0.5s ease; /* Smooth entry and fade-in */
      opacity: 0; /* Hidden by default */
      z-index: -1; /* Ensures it's above the card background */
    }
    .EnterpriseInstructor_Card:hover::before {
      top: -50%; /* Moves the half-circle into view */
      opacity: 1; /* Makes it visible */
    }


    .EnterpriseInstructor_Card_content{
      display: flex;
      height: 309.874px;
      flex-direction: column;
      align-items: center;
      gap: 7.54px;
      opacity: 1; /* Keep content hidden by default */
      transform: translateY(20px); /* Start the content slightly below */
      transition: opacity 0.3s ease, transform 0.3s ease; /* Add transition for the movement */
     }
     .EnterpriseInstructor_Card:hover .EnterpriseInstructor_Card_content {
      opacity: 1; /* Fade in */
      transform: translateY(0); /* Move the content to its original position */
    }
     .EnterpriseInstructor_Card_image {
       width: 120.632px;
       height: 120.632px;
       border-radius: 120.632px;
       flex-shrink: 0;
     }
     .EnterpriseInstructor_Card_name {
      color: #18172F;
      font-family: Inter;
      font-size: 12.063px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18.095px */
      letter-spacing: -0.229px;
     }
     .EnterpriseInstructor_Card_info {
      color: #18172F;
      font-family: Inter;
      width: 125.91px;
      font-size: 18.849px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 28.273px */
      letter-spacing: -0.358px;
      text-transform: capitalize;
     }
     .EnterpriseInstructor_Card_bio {
      color: #2A2951;
      font-family: Inter;
      font-size: 12.063px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18.095px */
      letter-spacing: -0.229px;
      text-transform: capitalize;
     }
     .EnterpriseInstructor_Card_button {
      display: flex;
      width: 123px;
      height: 40px;
      padding: 11px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background: #18172F;
      border: none;
      /* Style Button Text */
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 30px */
      letter-spacing: -0.38px;
      opacity: 0; /* Keep the button hidden by default */
      visibility: hidden; /* Keep the button hidden by default */
      transform: translateY(20px); /* Start the button slightly below */
      transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease; /* Add transition for the movement */
     }
     .EnterpriseInstructor_Card:hover .EnterpriseInstructor_Card_button {
      opacity: 1; /* Fade in */
      visibility: visible; /* Make it interactive */
      transform: translateY(0); /* Move the button to its original position */
    }