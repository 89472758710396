.coursepurchases_cointainer {
    display: flex;
    padding: 25px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
   }
.coursepurchases_Sec1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.456px;
   }