@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.Course_Container_forScroll {
  flex: 0 0 auto;
  width: 320px;
  position: relative;
}
.Course_Container{
     position: relative; /* Ensures child absolute elements are positioned relative to this container */
     display: flex;
     width: 316px;
     height: auto;
     flex-direction: column;
     align-items: center;
     gap: 8px;
     box-shadow: 0px 5px 15px 10px #EDEFFF;
     border-radius: 16px 16px 16px 16px;
     cursor: pointer  ;
  }
.Course_Sec1 {
    border-radius: 10px 10px 0px 0px;
    max-width: 316px;
}
.Course_Sec1_image {
    max-height: 230px;
    width: 311px;
    height: 186px !important;
    border-radius: 8px 8px 0px 0px;
}
.Course_Sec2 {
    display: flex;
    padding: 0px 8px;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.228px; 
}
.Course_Sec3{
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
/* style Text */
color: #3F52FC;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
letter-spacing: -0.38px;
text-transform: capitalize;
}
.Course_Sec3_Title {
   width: 280px !important;
   white-space: normal; /* يسمح بالنزول لأسطر جديدة */
   /*word-break: break-word; /* يكسر الكلمات الطويلة */
   text-overflow: ellipsis; /* يضيف "..." عند الاقتصاص */
 }
.Course_Sec4{
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 0px;
    align-self: stretch;
    /* Style Text*/
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Course_Sec5{
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 5px;
    /*gap: 5px;*/
    align-self: stretch;
    /*style text */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
   }
   .Course_Sec6{
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
   }
   .Course_Sec7{
    display: flex;
    padding: 8px 16px 16px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
   }
   .Course_Sec7_Home_guest {
      display: flex;
      position: absolute;
      bottom: 5px;
      right: 0;
      justify-content: flex-end;
      padding: 0px 16px 16px 16px;
      gap: 8px;
   }
   .cart-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    color: green;
    font-weight: bold;
    text-align: center;
  }
  .cart-message.error {
    color: red;
  }
  .course_InstructorImage {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 48px;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
  }
  .promo{
    display: flex;
    gap: 8px;
    align-items: center;
    color: #18172F;
  }
/* UI POPUP AT Figma */
.reound-popup {
  display: flex;
  width: 45vw;
  height: 40px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 57px;
  background: #3F52FC;
  position: absolute;
  bottom: 50px;
  /* Style Text */
  color: #14F90F;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 37.5px */
  letter-spacing: -0.475px;
  text-transform: capitalize;
  }
