@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  .Article_container {
    display: flex;
    width: 100%;
    margin-bottom: 2vh;
    margin-top: 2vh;
    
  }
  
  .Article_flex_container {
    display: flex; /* Enable flexbox */
    gap: 16px; /* Space between articles */
    justify-content: center; /* Center articles */

  }
  .Article_flex_container_WithWrap {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%; /* Ensure container takes full width */
}
  .Article_card {
    display: flex; /* Flex for card layout */
    flex-direction: column; /* Stack elements vertically */
    width: 320px; /* Set a fixed width for articles ,  this make responsive when width is 300px */
    height: 510px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    /*border: 1px solid #ccc; /* Optional: add border */
    border-radius: 8px; /* Optional: round corners */
    overflow: hidden; /* Prevent overflow */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow */
    flex-shrink: 0; /* Prevent shrinking of the card */
    cursor: pointer;
  }
  
  .Article_Sec1 img {
    width: 100%; /* Responsive image */
    /*height: 25vh; /* Maintain aspect ratio */
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 250px;
  }
  
  .author_icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 48px;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
  }
  .Article_Sec1{
    border-radius: 10px 10px 0px 0px;
  }
  .Article_Sec2{
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /*text*/
    color: #7E7E8C;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.228px;
    }
    .Article_Sec3{
        display: flex;
        padding: 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        /* Style Text*/
        color: #3F52FC;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.38px;
        text-transform: capitalize;
    }
    .Article_Sec4 {
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
        align-items: center;
        cursor: pointer;
        gap: 8px;
        max-width: 300px !important;
        align-self: stretch;
        /* style the text */
        color: #18172F;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.304px;
    }
    .Article_Sec5{
        display: flex;
        padding: 0px 0px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }
    .Author_information{
        display: flex;
        width: 316px;
        height: 50px;
        padding: 0px 16px;
        align-items: center;
        gap: 10px;
        
    }
    .Article_Sec5_Part1{
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
    }
      .Article_Sec5_Part2{
        display: flex;
        padding: 0px 0px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
       }
       .Article_Sec5_Part2_name{
        color: #3F52FC;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.38px;
       }
       .Article_Sec5_Part2_date{
        color: #7E7E8C;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.304px;
       }
       .discraption_modify {
        font-size: 16px;
       }
       .discraption_modify h1,h2,h3,h4,h5,h6 {
         font-size: 16px !important;
        }