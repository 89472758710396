@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.CourseDetails_Container{
   /* display: flex;*/
    padding: 0px 40px 56px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    max-width: 100%;
}
.CourseEnterPriseDetails_Container {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   flex-direction: column;
   padding-left: 10vw;
   padding-right: 10vw;

}
.CourseDetails_Sec1 {
    display: flex;
   /* padding: 0px 40px;*/
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* start to design Text at This Section  */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /*line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
   
}
.CourseDetails_Sec2 {
   display: flex;
}

.CourseDetails_Sec2_Part1 {
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
    padding-bottom: 32px;
}
/*
.CourseDetails_Sec2 {
   
}
*/
.CourseDetails_Sec2_Part2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}


.CourseDetails_Sec2_Part2_elementSaveCourse {
    display: flex;
    align-items: flex-start;
    gap: 60px;
}
.CourseDetails_Sec2_Part2_elementSaveCourse_item{
    display: flex;
    padding: 10px 8px;
    align-items: center;
    gap: 8px;
    /* style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.CourseDetails_Sec2_Part2_CourseHeader {
    display: flex;
    width: 776px;
    padding: 8px 0px 16px 0px;
    align-items: flex-start;
    gap: 64px;
}
.CourseHeader {
    display: flex;
    align-items: flex-start;
    gap: 58px;
    align-self: stretch;
    /* color */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    cursor: pointer;
}
.CourseHeader > .active {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    border-bottom: 5px solid #3F52FC;
    padding-bottom: 10px;
  }
  .CourseDetails_Sec2_Part2_CourseHeader {
    display: flex;
    flex-direction: column;
  }
/* Style Component Content */   /*****************************************************/
.CourseDetails_Content_Container { 
    display: flex;
  /*  width: 776px;*/
    padding-bottom: 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 88px;
}
.CourseDetails_Content_Sec1{
    display: flex;
    width: 680px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}
.CourseDetails_Content_question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    cursor:pointer ;
    /* Start style Text */
    color: #18172F;
    font-family: Inter;
    font-size: 18px;  /* this default in figma is 25px and i will low it to 20px */
    font-style: normal;
    font-weight: 400;  /* this default in figma is 500 at figma */
   /* line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
/* Style Answer */
.CourseDetails_Content_Answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
    list-style-type:decimal;
    /* style Text in list */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.CourseDetails_Content_Answer li::marker {
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
  }
  /*********************************  Course Location **************************************/
.CourseLocation_Container {
    display: flex;
    padding-bottom: 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}  
.CourseLocation_Sec1 {
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.CourseLocation_Sec2{
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.CourseLocation_Sec3_map{
    width: 723.484px;
    height: 789.182px;
}
.CourseLocation_Sec4 {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #EDEFFF;
    margin-top: 20px;
    width: 250px;
}
 /**/
 .CourseDetails_Sec2_Part2_CartElement {
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
 }
 .CourseDetails_Sec2_Part2_CartElement_part1 {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 16px;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
 }
 .CartElement_Date {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    /* start design text*/
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
 }
 /* design check Box*/
 /* src/DatePicker.css */
.date-picker {
    display: flex;
    align-items: center;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    
  }
  
  .date-option {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    width: 140px;
    border-radius: 6px;
    background: #18172F;
  }
  
  .date-option input[type="checkbox"] {
    display: block;
  }
  
  .date-option .date-text {
    color: #FFF;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.304px;
  }
  
 .select {
    background: #c90c8a;
 }
 /* the price cart */
 .CartElement_ThePrice {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    /* Style Text */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
 }
 .CartElement_ThePrice span{
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
 }
 /* start Style button*/
 .CartElement_button_B {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: #18172F;
    /* Style Text*/
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
    cursor:pointer 
 }
 .CartElement_button_B:hover {
    background: #08070e;
 }
 .CartElement_button_C {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: #EDEFFF;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
    cursor: pointer;
 }
 .CartElement_button_Cart {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
 }
 .CartElement_buy {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-decoration-line: underline;
 }
 .CartElement_buy_Content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer ;
 }
 .CartElement_Details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
 }
 .CartElement_Details_item {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Style Text */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
 }
 .Insructor_CardElement {
    display: flex;
    padding: 20px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
 }
 .Insructor_CardElement_Title {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
 }
 .Insructor_CardElement_information {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
 }
 .Insructor_CardElement_information_Part2_Name{
    color: #18172F;
    /*leading-trim: both;*/
   /* text-edge: cap;*/
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
 }
 .Insructor_CardElement_information_Part2_major {
    color: #5E6EFD;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
 }
 .Insructor_CardElement_information_Part2_Date {
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
 }
 .Insructor_CardElement_information_Part2_star {
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
 }
 .Insructor_CardElement_information_button {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: #3F52FC;
    /* Style Text*/
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
 }
 /* Description  Style */
 .Description_Container{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 56px;
}
.Description_Sec1 {
   display: flex;
   width: 680px;
   flex-direction: column;
   align-items: flex-start;
   gap: 48px;
   position: relative;
   /* Style Text */
   color: #18172F;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 160%; /* 25.6px */
   letter-spacing: -0.304px;
   text-transform: capitalize;
}
.Description_Sec1_header {
   color: #18172F;
   font-family: Inter;
   font-size: 25px;
   font-style: normal;
   font-weight: 500;
   line-height: 150%; /* 37.5px */
   letter-spacing: -0.475px;
   text-transform: capitalize;
}
.Description_Sec1_Expaned {
   display: flex;
   width: 680px;
   padding: 33px 0px;
   justify-content: center;
   align-items: center;
   gap: 4px;
   position: absolute;
   cursor: pointer;
   background: linear-gradient(0deg, #FFF 56.66%, rgba(255, 255, 255, 0.00) 100%);
   /*bottom: 0px;*/
   /* Style Text */
   color: #18172F;
   font-family: Inter;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 160%; /* 32px */
   letter-spacing: -0.38px;
   text-transform: capitalize;
}
.Description_Sec2 {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 48px;
   align-self: stretch;
}
.Description_Sec2_header {
   color: #18172F;
   font-family: Inter;
   font-size: 25px;
   font-style: normal;
   font-weight: 500;
   line-height: 150%; /* 37.5px */
   letter-spacing: -0.475px;
   text-transform: capitalize;
   margin-top: 40px;
}
.Description_Sec2_List ul {
   display: flex;
   width: 680px;
   flex-direction: column;
   align-items: flex-start;
   gap: 32px;
}
.Description_Sec2_List ul li{
   color: #18172F;
   font-family: Inter;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 160%; /* 32px */
   letter-spacing: -0.38px;
   text-transform: capitalize;
}

/* Similar classes Secction */
.SimilarClasses_Title{
   color: #5E6EFD;
   font-family: Inter;
   font-size: 25px;
   font-style: normal;
   font-weight: 500;
   line-height: 150%; /* 24px */
   letter-spacing: -0.304px;
   text-transform: capitalize;
}
.purchased {
   color: #14F90F;
   text-align: center;
   font-family: Inter;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: 150%; /* 30px */
   letter-spacing: -0.38px;
   text-transform: capitalize;
}